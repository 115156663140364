import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

/**
 * DataTable Component
 * @param {Array} data - The table data
 * @param {Array} columns - Column definitions
 * @param {Function} onTableChange - Callback for table change events (e.g., sorting)
 * @param {Array} defaultSorted - Default sorting configuration
 * @param {String} keyField - Unique key field for rows
 * @param {String} noDataIndication - Message to show when no data is available
 */
export const DataTablePaginated = ({
  data = [],
  columns = [],
  onTableChange,
  defaultSorted = [{ dataField: 'createdAt', order: 'desc' }],
  keyField = '_id',
  noDataIndication = 'No data found',
}) => {
  return (
    <BootstrapTable
      hover
      striped
      keyField={keyField} // Unique identifier for rows
      noDataIndication={noDataIndication} // Show when no data is available
      data={data} // Table data
      columns={columns} // Table columns
      rowClasses="table-row"
      defaultSorted={defaultSorted} // Set default sorting configuration
      onTableChange={onTableChange} // Optional: Callback for sorting, filtering, etc.
    />
  );
};
