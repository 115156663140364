import React, { useEffect, useState } from 'react';
import { DataTablePaginated } from '../components'; // Adjust the path if necessary
import { axios } from '../libs/axios';
import ReactPaginate from 'react-paginate';

export const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10); // Default to 10 items per page
  const [month, setMonth] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());

  // Fetch invoices with pagination and filters
  const fetchInvoices = async (page = 1, sizePerPage = 10, month = '', year = '') => {
    try {
      setLoading(true);
      const response = await axios.get('/invoices', {
        params: { page, sizePerPage, month, year },
      });
      console.log('Response Data:', response); // Debugging: Log API response
      setInvoices(response.invoices); // Update invoices
      setTotalRecords(response.total); // Update total records
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch invoices on mount and when filters/page/sizePerPage change
  useEffect(() => {
    fetchInvoices(currentPage, sizePerPage, month, year);
  }, [currentPage, sizePerPage, month, year]);

  // Handle filter changes
  const handleFilterChange = () => {
    setCurrentPage(1); // Reset to the first page when filters change
    fetchInvoices(1, sizePerPage, month, year);
  };

  // Handle downloading filtered data
  const handleDownload = async () => {
    try {
      const response = await axios.get('/invoices/download', {
        params: { month, year },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoices_${month || 'all'}_${year || 'all'}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  // Table columns
  const tableColumns = [
    { dataField: 'invoiceNumber', text: 'Invoice No' },
    { dataField: 'status', text: 'Status' },
    { dataField: 'firstname', text: 'First Name' },
    { dataField: 'lastname', text: 'Last Name' },
    { dataField: 'amount', text: 'Amount' },
    { dataField: 'service', text: 'Service' },
    { dataField: 'progressurl', text: 'Client Journey' },
    {
      dataField: 'createdAt',
      text: 'Registered At',
      formatter: (date) => new Date(date).toLocaleDateString(),
    },
  ];




  return (
    <div className="row table_v2">
      <div className="col p-0">
        <div className="x_panel chatbot-builder">
          <div className="header-menu d-flex justify-content-between">
            <h2>Invoices</h2>
            <div>
              <label htmlFor="pageSize">Items per page: </label>
              <select
                id="pageSize"
                value={sizePerPage}
                onChange={(e) => {
                  const newSizePerPage = parseInt(e.target.value, 10);
                  setSizePerPage(newSizePerPage);
                  setCurrentPage(1); // Reset to first page
                  fetchInvoices(1, newSizePerPage, month, year); // Fetch new data
                }}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <select value={month} onChange={(e) => setMonth(e.target.value)}>
                <option value="">All Months</option>
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                  </option>
                ))}
              </select>
              <select value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from({ length: 10 }, (_, i) => {
                  const yearOption = new Date().getFullYear() - i;
                  return (
                    <option key={yearOption} value={yearOption}>
                      {yearOption}
                    </option>
                  );
                })}
              </select>
              <button onClick={handleFilterChange}>Filter</button>
              {/* <button onClick={handleDownload}>Download</button> */}
            </div>
          </div>
          <div className="x_content">
             <div className="page-container">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                <DataTablePaginated data={invoices} columns={tableColumns} />
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={Math.ceil(totalRecords / sizePerPage)} // Dynamically calculated
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(e) => {
                    const newPage = e.selected + 1;
                    setCurrentPage(newPage);
                    fetchInvoices(newPage, sizePerPage, month, year);
                  }}
                  forcePage={currentPage - 1} // Sync selected page with `currentPage`
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </>
            )}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
