import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, TextareaComponent, SelectComp} from './Components'



export const LoginBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
 
  const bot = useSelector(Chatbot.get());



  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent bot={bot} name="Login" icon="login" data={data} node={node} start={start}/>
    </>
  );
});

export const LoginEditor = ({nodeid, block}) => {
  

 
  const {data} = block;

  if (!block) {
    return null;
  }

  const variableoptions = Chatbot.getBlockVariables();

  

  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
         


          
              <div className='section'>Andmed</div>

              <>VARIABLES: </><i>firstname, lastname, phone, email, pid</i>
            
              {/* 
              <InputComponent data={data} name="First name" dataname="firstname" onBlur={handleBlur}/>
              <InputComponent data={data} name="Last name" dataname="lastname" onBlur={handleBlur}/>
              <InputComponent data={data} name="Phone" dataname="phone" onBlur={handleBlur}/>
              <InputComponent data={data} name="Email" dataname="email" onBlur={handleBlur}/> */}


         
  </>
  

)
};