import React from 'react';
import { axios } from '../libs'; // Adjust the path as needed

const UpdateLawyersDataButton = () => {
  const handleUpdateClick = async () => {
    try {
      const response = await axios.get('/api/update-lawyers-data'); // Make sure the endpoint is correct
      console.log(response)
      if (response.status === 200) {
        console.log(response)
        alert(response.message); // Accessing the message properly
      } else {
        alert(`${response.message}`);
      }
    } catch (error) {
      console.error('Error updating lawyers data:', error);
      alert('An error occurred while updating lawyers data.');
    }
  };

  return (
    <button onClick={handleUpdateClick}>
      Update Lawyers Data
    </button>
  );
};

export default UpdateLawyersDataButton;
