const base_url = process.env.REACT_APP_BASE_URL;
const defaultColumnsOption = {
  sort: true,
};

const defaultPaginationOption = {
  page: 1,
  sizePerPage: 100,
  showTotal: true,
  withFirstAndLast: false,
  alwaysShowAllBtns: true,
  prePageText: 'Previous',
  nextPageText: 'Next',
  sizePerPageList: [
    {
      text: '10',
      value: 10,
    },
    {
      text: '50',
      value: 50,
    },
  ],
};

const languages = [
  {label: 'English', value: 'en'},
  {label: 'Estonian', value: 'et'},
  {label: 'Russian', value: 'ru'},
  {label: 'Latvian', value: 'lat'},
  {label: 'Lithuanian', value: 'lit'},
  {label: 'Polish', value: 'pol'},
  {label: 'Finnish', value: 'fin'},
  {label: 'German', value: 'gr'},
];

const opertors = [
  {label: 'Equals To', value: '=='},
  {label: 'Greater than', value: '>'},
  {label: 'Less than', value: '<'},
  {label: 'Greater than equals to', value: '>='},
  {label: 'Less than equals to', value: '<='},
  {label: 'Not equals to', value: '!='},
];
const operators_date = [
  {label: 'Before', value: 'd>'},
  {label: 'After', value: 'd<'},
  {label: 'On', value: 'd=='},
  {label: 'On or Before', value: 'd>='},
  {label: 'On or After', value: 'd<='},
];

const countries = [
  {
    label: 'Estonia',
    value: 'est',
  },
  {
    label: 'Latvia',
    value: 'lat',
  },
  {
    label: 'Lithuania',
    value: 'lit',
  },
  {
    label: 'Poland',
    value: 'pol',
  },
  {
    label: 'Finland',
    value: 'fin',
  },
  {
    label: 'German',
    value: 'ger',
  },
];

const validations = [
  {label: 'Optional', value: 'opt'},
  {label: 'No Rule', value: 'no-rule'},
  {label: 'Text', value: 'text'},
  {label: 'Email', value: 'email'},
  {label: 'Number', value: 'number'},
  {label: 'Address', value: 'address'},
  {label: 'Name', value: 'name'},
  {label: 'Time', value: 'time'},
  {label: 'Date', value: 'date'},
  {label: 'Money', value: 'money'},
  {label: 'Birthday', value: 'birthday'},
  {label: 'Custom', value: 'custom'},
];

const acceptFiles = ['.doc', '.docx', '.odt'];

const roles = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Lawyer',
    value: 'lawyer',
  },
];

const bookingTypes = [
  {
    label: 'Meeting',
    value: 'meeting',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'Web',
    value: 'web',
  },
];

const bookingCountries = [
  {
    label: 'Estonia',
    value: 'estonia',
    code: 'est',
  },
  {
    label: 'Poland',
    value: 'poland',
    code: 'pol',
  },
];

const bookingCities = [
  {
    label: 'Tallin',
    value: 'tallin',
    code: 'tal',
    country: 'estonia',
  },
  {
    label: 'Tartu',
    value: 'tartu',
    code: 'tar',
    country: 'estonia',
  },
  {
    label: 'Narva',
    value: 'narva',
    code: 'nar',
    country: 'estonia',
  },
  {
    label: 'Warsaw',
    value: 'warsaw',
    code: 'war',
    country: 'poland',
  },
  {
    label: 'Wroclaw',
    value: 'wroclaw',
    code: 'wro',
    country: 'poland',
  },
  {
    label: 'Torun',
    value: 'torun',
    code: 'tor',
    country: 'poland',
  },
];

const durations = [
  {
    label: '15 mins',
    value: '00:15:00',
  },
  {
    label: '30 mins',
    value: '00:30:00',
  },
  {
    label: '45 mins',
    value: '00:45:00',
  },
  {
    label: '1 hour',
    value: '01:00:00',
  },
];

const ckEditorInputBlock = {
  toolbar: ['bold', 'italic', 'link', 'insertImage'],
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,
  },
};

const ckEditorDecisionBlock = {
  toolbar: ['bold', 'italic', 'link', 'insertImage'],
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,
  },
};

const ckEditorMain = {
  toolbar: ['bold', 'italic', 'link', 'ctaButton', 'insertImage'],
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,
  }
 
};

const ckEditorBasic = {
  toolbar: ['bold', 'italic', 'link'],
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,
  },
};

const ckEditorEmailBlock = {
  toolbar: ['bold', 'italic', 'link', 'ctaButton'],
  link: {
    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
    addTargetToExternalLinks: true,
  },
};

const acceptedFileTypes = [
  {label: 'jpeg', value: '.jpg'},
  {label: 'png', value: '.png'},
  {label: 'doc', value: '.doc'},
  {label: 'docx', value: '.docx'},
  {label: 'pdf', value: '.pdf'},
];

const moneyCurrency = [
  {label: 'USD', value: 'usd', sign: '$'},
  {label: 'EUR', value: 'eur', sign: '€'},
  {label: 'PNL', value: 'pnl', sign: 'zł'},
];

export {
  base_url,
  languages,
  countries,
  opertors,
  validations,
  defaultColumnsOption,
  defaultPaginationOption,
  acceptFiles,
  roles,
  bookingTypes,
  bookingCountries,
  bookingCities,
  durations,
  ckEditorBasic,
  ckEditorMain,
  acceptedFileTypes,
  moneyCurrency,
  ckEditorEmailBlock,
  ckEditorInputBlock,
  ckEditorDecisionBlock,
  operators_date,
};
