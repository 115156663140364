import React, {useEffect, useState, useRef} from 'react';
import {Field} from 'formik';
import {filter as _filter} from 'lodash';
import { useLocation } from 'react-router-dom';

import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Form, DataTable} from '../components';
import Select from 'react-select';

import {languages, countries} from '../config';

import {axios} from '../libs';


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';

import {SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {InputComponent} from '../components/ChatbotBuilder/Components'

import {roles} from '../config';
import {User} from '../states/actions';
import UpdateLawyersDataButton from '../components/UpdateLawyersDataButton';

import UpdateWordpressDataButton from '../components/UpdateWordpressDataButton';

const DragHandle = sortableHandle(() => <span> : : </span>);



const SortableItem = SortableElement(({ value, handleInputChange, remove }) => (
  <div>
    <DragHandle />
    <input
      type="text"
      defaultValue={value}
      onBlur={handleInputChange}
    />
    <button onClick={remove}>Remove</button>

  </div>
));


const SortableList = SortableContainer(({ items, handleInputChange, remove }) => (
  <ul>
    {items.map((item, index) => (
      <SortableItem
        key={item.id}
        index={index}
        value={item.value}
        handleInputChange={(e) => handleInputChange(e, index)}
        remove={(e) => remove(item.id)}
      />
    ))}
  </ul>
));



export const Users = () => {
 
  const [newUser, setNewUser] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const addUser = () => {
    setNewUser(true)
    openModal();
    push('/users');
  }

/*   useEffect(() => {
    if (location.pathname === '/users/new') {
      openModal();
    }
  }, [location.pathname]); */

  const [refetch, setrefetch] = useState(false);
  const [initialValues, setinitialValues] = useState({email: '', role: ''});
  const {push} = useHistory();
  const {userId} = useParams();

  useEffect(() => {
    if (userId) {
      (async () => {
        const {user} = await axios.get(`/user/${userId}`);
        setinitialValues({
          _id: userId,
          email: user.email,
          role: user.role,
        });
      })();
    }
  }, [userId]);

  const tableColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'role',
      text: 'Role',
    },
  ];

  const userRequestColumns = [
    {
      dataField: 'firstName',
      text: 'Name',
      formatter: (v, {firstName, lastName}) => {
        return `${firstName ?? ''} ${lastName ?? ''}`;
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'request',
      text: 'Request Message',
    },
    {
      dataField: 'createdAt',
      text: 'Requested At',
      formatter: (date) => {
        return date.split('T')[0];
      },
    },
  ];

  const onSuccess = () => {
    setrefetch(false);
    if (userId) {
      setrefetch(true);
      push('/users');
    } else {
      setrefetch(true);
    }
  };

    const onEdit = ({_id}) => {
      setNewUser(false)
      push(`/users/${_id}`);
      openModal();
    };

    const onDelete = ({_id}) => {
      setrefetch(false);
      window.nConfirm({
        title: 'Warning',
        text: 'Are you sure you want to delete this user?',
        icon: 'fa-exclamation-circle',
        onYes: () => {
          deleteUser(_id);
        },
      });
    };

    const deleteUser = async (id) => {
      await axios.delete(`/user/${id}`);
      setrefetch(true);
    };

    const actions = (cell, row, rowIndex, formatExtraData) => {
      return (
        <div className="row row-cols-auto">
          <h6>
            <span
              onClick={() => {
                console.log('Accept');
              }}
              className="mx-1 badge bg-primary"
            >
              Accept
            </span>
          </h6>
          <h6>
            <span
              onClick={() => {
                console.log('Reject');
              }}
              className="mx-1 badge bg-danger"
            >
              Reject
            </span>
          </h6>
  
          {/* <i onClick={() => onEdit(row)} className="col edit-icon fas fa-pencil-alt fa-lg"></i> */}
        </div>
      );
    };
  
    const userRequestActions = {
      dataField: '',
      text: 'Actions',
      formatter: actions,
      style: {
        width: 200,
      },
    };
  





  return (
    <>

      <div className="row table_v2">
        <div className="col p-0">
          <div className="x_panel chatbot-builder">

            <div className="header-menu d-flex justify-content-between">

             {/*  <h2>
                Documents
              </h2> */}
             

              <div className="d-flex justify-content-end page-top-container">
                  
                      <div className='btn-add link' onClick={addUser}>
                         + Add User
                      </div>

                      <UpdateLawyersDataButton />

                      {/* <UpdateWordpressDataButton /> */}
                 
              </div>


             
            </div>


            <div className="x_content">

                <div className="page-container">



                      <DataTable


                      refetch={refetch}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      url="/user/all"
                      dataKey="users"
                      tableHeading="Users"
                      columns={tableColumns}
                    
                     />
               </div>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="modalclose" onClick={closeModal}>
              &times;
            </span>

            <div className='modal_header mb-4 my-4'>
              {newUser ?(
              <h2>Add new user</h2>
              ):(
                <h2>Edit user</h2>
              )}
            </div>
          
            <Form url="/user" usePUT={userId ? true : false} initialValues={initialValues} onSuccess={onSuccess}>
             
               {/*  <div className="row mb-5">
                  <label htmlFor="firstName" className="form-label comp-header">
                     First Name
                  </label>

                  <div className="">
                    <Field
                      as="input"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      placeholder="First name"
                      autoComplete="off"
                    />
                  </div>
                  
                </div> */}
             
                <div className="row mb-5">
                  <label htmlFor="email" className="form-label comp-header">
                     User email
                  </label>

                  <div className="">
                    <Field
                      as="input"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="User email"
                      autoComplete="off"
                    />
                  </div>
                  
                </div>


                <div className="row mb-5">
                  <label htmlFor="email" className="form-label comp-header">
                     Role
                  </label>

                  <div className="">
                      <Field id="role" name="role">
                          {({field, form}) => (
                            <Select
                              id="role"
                              name={field.name}
                              value={roles.find((i) => i.value === form.values.role)}
                              options={roles}
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: 0,
                                }),
                              }}
                            />
                          )}
                        </Field>

                  </div>
                  
                </div>

          
                   
                <div className="d-flex justify-content-end">
                  <button className="light-btn" onClick={closeModal}>
                    Cancel
                  </button>
                  <button type="submit" className="main-btn">
                    Save
                  </button>
                </div>


              </Form>

          </div>
        </div>
      )}


  

  {/*  {User.isAdmin() && (
        <div className="row">
          <div className="col">
            <div className="x_panel">
              <div className="x_title">
                <h2>User Requests</h2>
                <div className="clearfix"></div>
              </div>
              <div className="x_content">
                <DataTable
                  url="/user-request"
                  dataKey="userRequest"
                  columns={userRequestColumns}
                  actions={userRequestActions}
                  // onDelete={() => {}}
                  // onEdit={onEdit}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
