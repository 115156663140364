import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {Handle} from 'reactflow';
//import {Handle} from 'react-flow-renderer';
import {Form} from '../';
import {Chatbot} from '../../states/actions';
import {useSelector} from 'react-redux';
import {onSuccess, onEdit, onSetStart, onRemove, onClose, isLightColor} from '../../helpers/block-helper';
import {Link} from 'react-router-dom';
import {IntNodeComponent, InputComponent, SelectComp} from './Components'



export const MatchBlock = React.memo((node) => {
  const block = Chatbot.getBlock(node.id);
  const {start, review} = useSelector(Chatbot.get());
  const bot = useSelector(Chatbot.get());



  if (!block) {
    return null;
  }
  const {data} = block;

  return (
    <>
        <IntNodeComponent bot={bot} name="Match" icon="match" data={data} node={node} start={start}/>
    </>
  );
});

export const MatchEditor = ({nodeid, block}) => {
  

 
  const {data} = block;

  if (!block) {
    return null;
  }

  const variableoptions = Chatbot.getBlockVariables();

  let matchType = [
    {
      label: 'Match',
      value: 'match',
      content: 'match',
    },
    {
      label: 'Personal',
      value: 'personal',
      content: 'personal',
    },
  ];


  const handleBlur = (e) => {   
    Chatbot.setBlockData(block.id, {[e.target.name]: e.target.value});
  };


return (
  <>
         
         {/* <Form url="/block/document" initialValues={data} onSuccess={onSuccess} successNotification={false}>
             */}

              {/* <div className="mb-2">
 
                <label htmlFor="documentId" className="form-label comp-header">
                  Spreadsheet
                </label>
                <Select
                  id="documentId"
                  name="documentId"
                  options={documents}
                  placeholder="Select Document"
                  value={documents?.find((i) => i.value === data.documentId)}
                  className="form-control form-control-sm editor-select"
                  classNamePrefix="editor-select"
                  onChange={(option) => {
                    Chatbot.setBlockData(block.id, {documentId: option.value, documentName: option.label});
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 0,
                      border: 'none',
                    }),
                  }}
                />

              </div> */}

              
              <div className='section'>Sisend</div>

              <SelectComp data={data} name="Match type" dataname="matchtype" placeholder="Select" options={matchType} block={block} value="value"/>

              <SelectComp data={data} name="Service" dataname="service" placeholder="Select" options={variableoptions} block={block} value="label"/>


              {data.matchtype === 'match' ? (
                <>
                      
              <SelectComp data={data} name="Legalfield" dataname="legalfield" placeholder="Select" options={variableoptions} block={block} value="label"/>

              <SelectComp data={data} name="Location" dataname="location" placeholder="Select" options={variableoptions} block={block} value="label"/>

              <SelectComp data={data} name="Language (service)" dataname="language" placeholder="Select" options={variableoptions} block={block} value="label"/>




              <div className='section'>Algorütm</div>


              <InputComponent data={data} name="Speed" dataname="speed" onBlur={handleBlur}/>

              <InputComponent data={data} name="Rating" dataname="rating" onBlur={handleBlur}/>

              <InputComponent data={data} name="Price" dataname="price" onBlur={handleBlur}/>
                </>
                ):(
                  <>
                    <SelectComp data={data} name="Lawyer" dataname="lawyerid" placeholder="Select" options={variableoptions} block={block} value="label"/>
                  </>
                )
              }



              {/* <InputComponent data={data} name="Waiting message" dataname="waitmessage" onBlur={handleBlur}/>
 */}

              
             


         {/* </Form> */}

         
  </>
  

)
};